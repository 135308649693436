import React from 'react';
import Helmet from 'react-helmet';
import {graphql, useStaticQuery} from 'gatsby';
import settings from '../../settings';
import BackgroundImage from 'gatsby-background-image';
import Layout from "../../components/Layout";
import Banner from "../../components/Banner";
import SectionImageLeft from '../../components/SectionImageLeft';
import SectionImageRight from '../../components/SectionImageRight';
import TextHeadingParagraph from '../../components/TextHeadingParagraph';

import Carousel from "../../components/Carousel";
import Label from "../../components/Carousel/SlideLabel";

export default function() {

	const section_height = '400px';

	const {
			imageMobile_retaining_01,		imageDesktop_retaining_01,
			imageMobile_freestanding_01,	imageDesktop_freestanding_01,
			imageMobile_freestanding_02,	imageDesktop_freestanding_02,
		//	imageMobile_freestanding_03,	imageDesktop_freestanding_03,
			imageMobile_job_01_after_01,	imageDesktop_job_01_after_01,
			imageMobile_job_01_after_02,	imageDesktop_job_01_after_02,
			imageMobile_job_01_during_01,	imageDesktop_job_01_during_01,
			imageMobile_job_01_before_01,	imageDesktop_job_01_before_01,
			imageMobile_job_01_before_02,	imageDesktop_job_01_before_02,
			imageMobile_patio_01,			imageDesktop_patio_01,
			imageMobile_walkway_01,			imageDesktop_walkway_01,
			imageMobile_steps_01,			imageDesktop_steps_01,
	} = useStaticQuery(
		graphql`
		  query {

			imageMobile_retaining_01: file(relativePath: { eq: "pages/hardscapes/retaining.01.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:320) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageDesktop_retaining_01: file(relativePath: { eq: "pages/hardscapes/retaining.01.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:640) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageMobile_freestanding_01: file(relativePath: { eq: "pages/hardscapes/freestanding.01.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:320) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageDesktop_freestanding_01: file(relativePath: { eq: "pages/hardscapes/freestanding.01.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:640) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageMobile_freestanding_02: file(relativePath: { eq: "pages/hardscapes/freestanding.02.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:320) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageDesktop_freestanding_02: file(relativePath: { eq: "pages/hardscapes/freestanding.02.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:640) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageMobile_freestanding_03: file(relativePath: { eq: "pages/hardscapes/freestanding.03.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:320) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageDesktop_freestanding_03: file(relativePath: { eq: "pages/hardscapes/freestanding.03.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:640) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageMobile_job_01_after_01: file(relativePath: { eq: "pages/hardscapes/job.01.after.01.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:320) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageDesktop_job_01_after_01: file(relativePath: { eq: "pages/hardscapes/job.01.after.01.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:640) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageMobile_job_01_after_02: file(relativePath: { eq: "pages/hardscapes/job.01.after.02.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:320) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageDesktop_job_01_after_02: file(relativePath: { eq: "pages/hardscapes/job.01.after.02.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:640) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageMobile_job_01_during_01: file(relativePath: { eq: "pages/hardscapes/job.01.during.01.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:320) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageDesktop_job_01_during_01: file(relativePath: { eq: "pages/hardscapes/job.01.during.01.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:640) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageMobile_job_01_before_01: file(relativePath: { eq: "pages/hardscapes/job.01.before.01.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:320) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageDesktop_job_01_before_01: file(relativePath: { eq: "pages/hardscapes/job.01.before.01.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:640) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageMobile_job_01_before_02: file(relativePath: { eq: "pages/hardscapes/job.01.before.02.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:320) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageDesktop_job_01_before_02: file(relativePath: { eq: "pages/hardscapes/job.01.before.02.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:640) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageMobile_patio_01: file(relativePath: { eq: "pages/hardscapes/patio.01.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:320) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageDesktop_patio_01: file(relativePath: { eq: "pages/hardscapes/patio.01.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:640) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageMobile_walkway_01: file(relativePath: { eq: "pages/hardscapes/walkway.01.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:320, maxHeight:300, cropFocus:CENTER) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageDesktop_walkway_01: file(relativePath: { eq: "pages/hardscapes/walkway.01.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:640, maxHeight:400, cropFocus:CENTER) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageMobile_steps_01: file(relativePath: { eq: "pages/hardscapes/istock.steps.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:320, cropFocus:EAST) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

			imageDesktop_steps_01: file(relativePath: { eq: "pages/hardscapes/istock.steps.jpg" }) {
			  childImageSharp {
				fluid(quality:80, maxWidth:640, cropFocus:EAST) {
				  ...GatsbyImageSharpFluid_withWebp
				}
			  }
			}

		  }
		`
  );

	const image_sources	=	{

		job_01_after_01	:	[
			imageMobile_job_01_after_01.childImageSharp.fluid,
			{
			...imageDesktop_job_01_after_01.childImageSharp.fluid,
				media: `(min-width: 768px)`,
			},
		],

		job_01_after_02	:	[
			imageMobile_job_01_after_02.childImageSharp.fluid,
			{
			...imageDesktop_job_01_after_02.childImageSharp.fluid,
				media: `(min-width: 768px)`,
			},
		],

		job_01_during_01	:	[
			imageMobile_job_01_during_01.childImageSharp.fluid,
			{
			...imageDesktop_job_01_during_01.childImageSharp.fluid,
				media: `(min-width: 768px)`,
			},
		],

		job_01_before_01	:	[
			imageMobile_job_01_before_01.childImageSharp.fluid,
			{
			...imageDesktop_job_01_before_01.childImageSharp.fluid,
				media: `(min-width: 768px)`,
			},
		],

		job_01_before_02	:	[
			imageMobile_job_01_before_02.childImageSharp.fluid,
			{
			...imageDesktop_job_01_before_02.childImageSharp.fluid,
				media: `(min-width: 768px)`,
			},
		],

	};

	return (
		<Layout>
			<Helmet>
				<title>Hardscapes • Blackstone VT</title>
				<link rel="canonical" href={`${settings.host.url}/hardscapes`} />
				<meta
					name="description"
					content="Authentic and premium quality masonry in Southern Vermont. Examples of stonework for freestanding & retaining walls along with patios & walkways."
				/>
			</Helmet>
			<div style={{paddingBottom:'50px'}} id="walls">
				<Banner shadowBottom={true} shadowTop={true} isH1={true}>Hardscapes</Banner>

				<SectionImageRight
					imageMobile={imageMobile_retaining_01}
					imageDesktop={imageDesktop_retaining_01}
					imageAlt="Retaining Wall"
					height={section_height}>
					<TextHeadingParagraph heading="Retaining Walls">'Edging' placed at the base of retaining walls can help soften appearance.</TextHeadingParagraph>
				</SectionImageRight>
				<SectionImageLeft
					imageMobile={imageMobile_freestanding_01}
					imageDesktop={imageDesktop_freestanding_01}
					imageAlt="Natural Stone Wall"
					height={section_height}>
					<TextHeadingParagraph heading="Natural Stone">
						Timeless. Authentic. This is what you love about Vermont.
						We know it well and are proud to deliver when customers ask for this iconic style.
					</TextHeadingParagraph>
				</SectionImageLeft>
				<SectionImageRight
					imageMobile={imageMobile_freestanding_02}
					imageDesktop={imageDesktop_freestanding_02}
					imageAlt="Freestanding Stone Wall"
					height={section_height}>
					<TextHeadingParagraph heading="Freestanding">
						Make a statement. Establish boundaries.
						For whichever is your purpose, if it's made of stone, we can build it.
					</TextHeadingParagraph>
				</SectionImageRight>

			</div>
			<div style={{paddingBottom:'50px'}} id="patios-n-walkways">
				<Banner shadowBottom={true} shadowTop={true}>Patios & Walkways</Banner>

				<SectionImageRight
					imageMobile={imageMobile_patio_01}
					imageDesktop={imageDesktop_patio_01}
					imageAlt="Stone Patio"
					height={section_height}>
					<TextHeadingParagraph heading="Patios">
						The primary indicator of quality patio stonework comes five years after its laying.
						Stones that continue to lay flat without tilting or raised edges show that your mason did the extra work needed to before laying the stones.
						Preparing the ground beneath the stones is more important than the initial appearance of beautiful new stonework.
					</TextHeadingParagraph>
				</SectionImageRight>

				<SectionImageLeft
					imageMobile={imageMobile_walkway_01}
					imageDesktop={imageDesktop_walkway_01}
					imageAlt="Stone Walkway"
					height={section_height}>
					<TextHeadingParagraph heading="Walkways">
						Pathways and walkways create an extension of the home by making unusable places usable.
						While providing practical living value, these features can also increase real estate value.
						If done correctly, a quality pathway or walkway project made with good materials is a good investment.
						<sup title="source: https://www.thespruce.com/walkways-and-pathways-2132017#mntl-sc-block_1-0-26">
							<span role="img" aria-label="Book Icon"> 📖</span>
						</sup>
					</TextHeadingParagraph>
				</SectionImageLeft>

				<SectionImageRight
					imageMobile={imageMobile_steps_01}
					imageDesktop={imageDesktop_steps_01}
					imageAlt="Stone Steps"
					height={section_height}>
					<TextHeadingParagraph heading="Steps">
						Tight seams in sturdy placement on level grade is a standard description of our stonework.
						Stones do not move and neither does our attention to quality.
					</TextHeadingParagraph>
				</SectionImageRight>

			</div>
			<div style={{paddingBottom:'50px'}} id="before-n-after">
				<Banner shadowBottom={true} shadowTop={true}>Before <span role="img" aria-label="Recycle Symbol">♻️</span> After</Banner>
				<Carousel>
				
					<BackgroundImage alt="Job 1 - After Job Image #1" fluid={image_sources.job_01_after_01} className="slide_content" ><Label>After #1</Label></BackgroundImage>
					<BackgroundImage alt="Job 1 - After Job Image #2" fluid={image_sources.job_01_after_02} className="slide_content" ><Label>After #2</Label></BackgroundImage>
					<BackgroundImage alt="Job 1 - During Job Image #1" fluid={image_sources.job_01_during_01} className="slide_content" ><Label>During</Label></BackgroundImage>

				</Carousel>
				<Carousel>

					<BackgroundImage alt="Job 1 - Before Image #1" fluid={image_sources.job_01_before_01} className="slide_content" ><Label>Before #1</Label></BackgroundImage>
					<BackgroundImage alt="Job 1 - Before Image #2" fluid={image_sources.job_01_before_02} className="slide_content" ><Label>Before #2</Label></BackgroundImage>

				</Carousel>
			</div>
		</Layout>
	);
}
